import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Quote from "./Quote";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "quote"
    }}>{`Quote`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={Quote} mdxType="Props" />
    <h2 {...{
      "id": "container-types"
    }}>{`Container Types`}</h2>
    <ul>
  <li>Double quotes container will show only when a heading tag (h1, h2, h3, etc) is used.</li>
  <li>Single quote container will be used in all other cases.</li>
    </ul>
    <h2 {...{
      "id": "double-quotes-basic-usage"
    }}>{`Double Quotes Basic Usage`}</h2>
    <Playground __position={2} __code={'<Quote float=\"center\" width=\"680px\">\n  <h1>\n    LOREM IPSUM\n    <br />\n    DOLOR SIT AMET CONSECTUR\n  </h1>\n</Quote>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Quote,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Quote float="center" width="680px" mdxType="Quote">
    <h1>
      LOREM IPSUM
      <br />
      DOLOR SIT AMET CONSECTUR
    </h1>
  </Quote>
    </Playground>
    <h2 {...{
      "id": "single-quote-basic-usage"
    }}>{`Single Quote Basic Usage`}</h2>
    <Playground __position={3} __code={'<Quote\n  float=\"left\"\n  width=\"730px\"\n  author=\"Expert Name\"\n  details=\"Lorem ipsum dolor sit amet\"\n>\n  <p>\n    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\n    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n    commodo consequat.\n  </p>\n</Quote>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Quote,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Quote float="left" width="730px" author="Expert Name" details="Lorem ipsum dolor sit amet" mdxType="Quote">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.</p>
  </Quote>
    </Playground>
    <h2 {...{
      "id": "quote-without-max-width"
    }}>{`Quote Without Max Width`}</h2>
    <p>{`When a custom maximum width is not defined, the quote container adapts to the screen full width and aligns its content.`}</p>
    <Playground __position={4} __code={'<Quote float=\"center\">\n  <h1>\n    LOREM IPSUM\n    <br />\n    DOLOR SIT AMET CONSECTUR\n  </h1>\n</Quote>\n<Quote author=\"Expert Name\" details=\"Lorem ipsum dolor sit amet\">\n  <p>\n    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\n    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n    commodo consequat.\n  </p>\n</Quote>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Quote,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Quote float="center" mdxType="Quote">
    <h1>
      LOREM IPSUM
      <br />
      DOLOR SIT AMET CONSECTUR
    </h1>
  </Quote>
  <Quote author="Expert Name" details="Lorem ipsum dolor sit amet" mdxType="Quote">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.</p>
  </Quote>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      